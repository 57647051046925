import { apiSlice } from "./apiSlice"

const extendedApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getDashboard: build.query({
			query: () => ({url: '/dashboard', method: 'get' }),
			providesTags: ['Dashboard'],
		}),
		postCompleteOnboarding: build.mutation({
			query: ({type}) => ({url: '/user/onboarding/complete/' + type, method: 'post' }),
		}),
	}),
	overrideExisting: false,
})

export const {
	useGetDashboardQuery,
	usePostCompleteOnboardingMutation,
	
	util: { getRunningQueriesThunk, resetApiState, getState }
	
} = extendedApi

export const { getDashboard } = extendedApi.endpoints;
