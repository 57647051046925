import React, {useState} from 'react';
import {HiChartBar, HiDotsVertical, HiMenu, HiOutlineTrendingUp, HiPencilAlt, HiTrash} from "react-icons/hi";
import classNames from "classnames";
import ContextMenu from "../../components/ContextMenu";
import ContextMenuItem from "../../components/ContextMenuItem";
import Modal from "../../components/Modal";
import Link from "next/link";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {shortenNumber} from "../../helpers/utils";

function PageItem(props) {
	const [expanded, setExpanded] = useState(false);
	const [menuExpanded, setMenuExpanded] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	
	
	function handleCloseDeleteModal() {
		setMenuExpanded(false)
		setShowDeleteModal(false)
	}
	
	function openDeleteModal() {
		setMenuExpanded(false)
		setShowDeleteModal(true)
	}
	
	function toggleOptionsMenu() {
		setMenuExpanded(!menuExpanded)
	}
	
	function handleProcessDeleteModal(e) {
		setMenuExpanded(false)
		setExpanded(false)
		props.delete(e, props.page.id)
	}
	
	
	function handleCloseModal() {
		setShowModal(false)
	}
	
	function handleProcessModal() {
		setShowModal(false)
	}
	
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({id: props.page.id});
	
	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	};
	
	return (
		<>
			<div ref={setNodeRef} style={style} className="block shadow bg-white rounded flex align-items-center items-center mb-5">
				<div
					{...attributes} {...listeners}
					className="drag-cursor dragg touch-none select-none px-5 py-4 "
				>
					<HiMenu className="w-5 h-5" />
				</div>
				<div className="w-full pr-5 py-4">
					<div className="top flex items-center">
						{/*<div className="icon flex-none w-8 mr-2 sm:block hidden">*/}
						{/*	<div className={classNames(*/}
						{/*		'focus:outline-none',*/}
						{/*		'cursor-pointer',*/}
						{/*		'w-7',*/}
						{/*		'h-7',*/}
						{/*		'bg-' + props.page.color + '-500',*/}
						{/*		'text-white',*/}
						{/*		'rounded-full',*/}
						{/*		'p-1'*/}
						{/*	)}*/}
						{/*	></div>*/}
						{/*</div>*/}
						<div className="preview flex-grow">
							<div className="title cursor-pointer font-medium text-lg sm:text-xl">
								<Link href={"/pages/" + props.page.id} className="inline-block align-middle">
									<span>{props.page.slug.name}</span>
									&nbsp;
									{props.page.is_primary_admin ?
										''
										:
										<span className="badge-primary align-middle cursor-pointer">Admin</span>
									}
								</Link>
							</div>
						</div>
						
						<Link href={"/pages/" + props.page.id + "/analytics"} legacyBehavior>
							<a className="stats block flex mr-5 text-sm items-center bg-blue-100 py-0.5 px-1 rounded">
								<HiChartBar className="w-4 h-4 inline-block" />
								<div className="ml-1">{shortenNumber(props.page.clicks)}</div>
							</a>
						</Link>
						
						<div className="relative">
							<HiDotsVertical onClick={toggleOptionsMenu} className="w-6 h-6 cursor-pointer flex-none select-none z-0" />
							{menuExpanded &&
								<ContextMenu close={toggleOptionsMenu}>
									<ContextMenuItem
										text="Edit"
										href={"/pages/" + props.page.id}
										icon={<HiPencilAlt className="icon" />}
									/>
									
									<ContextMenuItem
										text="Analytics"
										href={"/pages/" + props.page.id + "/analytics"}
										icon={<HiOutlineTrendingUp className="icon" />}
									/>
									
									<ContextMenuItem
										text="Delete"
										icon={<HiTrash className="icon" />}
										onClick={openDeleteModal}
										destructive={true}
									/>
								</ContextMenu>
							}
							
							<Modal
								title="Change Page Url"
								show={showModal}
								close={handleCloseModal}
								processSubmit={handleProcessModal}
							>
								<p className="text-sm text-gray-500">
									{process.env.NEXT_PUBLIC_ORIGINAL_URL}
									<input type="text" className="border p-2"/>
								</p>
							</Modal>
							
							<Modal
								title="Delete Page"
								type="danger"
								submitText="Delete Page"
								show={showDeleteModal}
								close={handleCloseDeleteModal}
								processSubmit={e => handleProcessDeleteModal(e)}
							>
								<p className="text-sm text-gray-500">
									Are you sure you want to delete this page? You will not be able to recover this page once it&apos;s deleted!
								</p>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PageItem;
