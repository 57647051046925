import React, {useState, useEffect} from 'react';
import { BsFillBarChartFill} from 'react-icons/bs'
import Layout from "../components/Layout";
import Modal from "../components/Modal";
import PageItem from "../components/Dashboard/PageItem";
import {getPages, useDeletePageMutation, useGetPagesQuery, usePostCreatePageMutation, usePostReorderPageMutation} from "../rtk-api/pages";
import Router, {useRouter} from "next/router";
import Spinner from "../components/Spinner";
import {getDashboard, useGetDashboardQuery, getRunningQueriesThunk, usePostCompleteOnboardingMutation} from "../rtk-api/general";
import {getMe, useGetMeQuery} from "../rtk-api/auth";
import {useGeneralStore} from "../stores/general";
import {shortenNumber, showError} from "../helpers/utils";
import Link from "next/link";
import {wrapper} from "../store";

import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';

import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {useDispatch} from "react-redux";
import {apiSlice} from "../rtk-api/apiSlice";
import {FiLink} from "@react-icons/all-files/fi/FiLink";
import { MdBolt } from "react-icons/md";
import { useTour } from "@reactour/tour";

export const getServerSideProps = wrapper.getServerSideProps(store => async ({req, params}) => {
	
	store.dispatch(getDashboard.initiate());
	store.dispatch(getPages.initiate());
	store.dispatch(getMe.initiate());
	
	await Promise.all(store.dispatch(getRunningQueriesThunk()));
	
	return {
		props: {},
	};
});

function Dashboard() {
	const router = useRouter()
	const dispatch = useDispatch();
	const { setIsOpen, setSteps, setCurrentStep } = useTour()
	const {data: meInfo, isLoading:isMeLoading, isSuccess:isMeSuccess} = useGetMeQuery();
	const [postCompleteOnboarding] = usePostCompleteOnboardingMutation();
	
	const {data: pagesList, isSuccess} = useGetPagesQuery()
	const {data: dashboardInfo, isSuccess:isDashboardInfoSuccess} = useGetDashboardQuery()
	
	useEffect(() => {
		if (meInfo?.data.status !== 8) {
			setCurrentStep(0);
			setSteps([
				{
					selector: 'main',
					content: "Welcome to PageName! Let's take a moment to show you around. Here is a quick view of your dashboard",
				},
				{
					selector: "#pages",
					content: () => (
						<div>
							<span>Here are your pages. We already created one for you but you can create more later!</span>
						</div>
					)
				}
			])
			setIsOpen(!meInfo?.data.settings.onboarding_completed)
		}
	}, [setCurrentStep, setSteps]);
	
	const [showPage, setShowPage] = useState(false)
	const [postCreatePage, {data:createPageData, isLoading:isPageCreateLoading, isSuccess:isPostCreateSuccess}] = usePostCreatePageMutation();
	const [deletePage] = useDeletePageMutation();
	const [slug, setSlug] = useState('');
	const [errorList, setErrorList] = useState({});
	const [pageTransition, setPageTransition] = useState(false);
	const setShowGlobalLoading = useGeneralStore((state) => state.setShowGlobalLoading)
	const [reorderPage] = usePostReorderPageMutation();
	
	useEffect(() => {
		if (meInfo?.data.status === 8) {
			router.push(process.env.NEXT_PUBLIC_ORIGINAL_URL + '/dashboard/social/finalize')
		} else if (meInfo?.data.status === 9) {
			router.push(process.env.NEXT_PUBLIC_ORIGINAL_URL + '/dashboard/downgrade')
		} else {
			setShowPage(true)
		}
	}, []);
	
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);
	
	async function handleReorder(event) {
		const {active, over} = event;
		
		const oldIndex = pagesList?.data.indexOf(pagesList?.data.find((s) => s.id === active.id))
		const newIndex = pagesList?.data.indexOf(pagesList?.data.find((s) => s.id === over.id))
		let order = arrayMove(pagesList?.data, oldIndex, newIndex);
		
		dispatch(apiSlice.util.updateQueryData('getPages', undefined, (draft) => {
			Object.assign(draft, {
				data: order
			})
		}))
		
		let finalOrder = []

		order.map((data, index) =>{
			finalOrder.push({
				page_id:data.id,
				order:index
			})
		})
		
		await reorderPage({
			data: {
				pages: finalOrder
			}
		})
	}
	
	
	// Todo: this came from the fact that the delete page was not a smooth transition
	// 	im sure there is a better way to do this but this should suffice for now.
	setShowGlobalLoading(false);
	
	async function handleDelete(e, id) {
		e.preventDefault()
		await deletePage({
			data: {
				page_id: id
			}
		})
		
		// this is because we removed the invalidate tag for now because it was having 404 issues
		window.location.href = '/'
	}
	
	const [showModal, setShowModal] = useState(false);
	
	function handleCloseModal() {
		setShowModal(false)
	}
	
	function openModal() {
		setShowModal(true)
	}
	
	async function handleProcessModal(e) {
		e.preventDefault();
		await postCreatePage({
			data: {
				slug
			}
		})
			.unwrap()
			.then(() => {
				setShowModal(false)
				setPageTransition(true)
			})
			.catch((data) => {
				setErrorList(data.data.errors)
			});
	}
	
	useEffect(() => {
		if (isPostCreateSuccess) {
			Router.push('/pages/' + createPageData.id)
		}
	}, [isPostCreateSuccess]);
	
	useEffect(() => {
		if (!meInfo?.data.settings.onboarding_completed && meInfo?.data.status != 8) {
			postCompleteOnboarding({
				type: 'one'
			})
		}
	}, [])
	
	if (showPage) {
		return (
			<Layout title="Dashboard">
				<div className="App m-auto pb-20">
					<Spinner show={pageTransition || isMeLoading} full size={500}/>
					
					{!isMeLoading && !pageTransition &&
						<>
							<div className="container mx-auto md:flex mb-4" id="info">
								<div className="md:flex md:justify-between md:container mx-auto px-2 sm:px-20 mt-2">
									<div className="mb-2 md:mb-0 md:w-1/4 p-2 bg-white shadow rounded flex items-center">
										<div className="bg-purple-600 p-2">
											<MdBolt className="text-white" size={30} />
										</div>
										<div className="ml-4">
											<div className="text-md lg:text-xl">{dashboardInfo?.plan_name}</div>
											<div className="text-xs">Your plan</div>
										</div>
									</div>
									
									<div className="mb-2 md:mb-0 md:w-1/4 p-2 bg-white shadow rounded flex items-center">
										<div className="bg-purple-600 p-2">
											<BsFillBarChartFill className="text-white" size={30} />
										</div>
										<div className="ml-4">
											<div className="text-xl">{shortenNumber(dashboardInfo?.links_clicks_total)}</div>
											<div className="text-xs">Total Clicks</div>
										</div>
									</div>
									
									<div className="mb-2 md:mb-0 md:w-1/4 p-2 bg-white shadow rounded flex items-center ">
										<div className="bg-purple-600 p-2">
											<FiLink className="text-white" size={30} />
										</div>
										<div className="ml-4">
											<div className="text-xl">{shortenNumber(dashboardInfo?.content_total)}</div>
											<div className="text-xs">Total Content</div>
										</div>
									</div>
								</div>
							</div>
							
							<div className="main md:container mx-auto px-2 sm:px-20 mt-8 sm:mt-16" id="pages">
								<div className="flex w-full items-center justify-between">
									<h1 className="text-2xl sm:text-3xl">My Pages ({pagesList?.data.length ?? 0})</h1>
									
									{pagesList?.data.length >= dashboardInfo?.pages_limit && dashboardInfo?.pages_limit != -1 ?
										<Link href={process.env.NEXT_PUBLIC_ORIGINAL_URL + '/pricing'} legacyBehavior>
											<a className="btn btn-primary px-4 py-2 upgrade-color">Create Page</a>
										</Link>
										:
										<span className="btn btn-primary px-4 py-2" onClick={openModal}>Create Page</span>
									}
								</div>
								{!pagesList?.data.length &&
									<div className="mt-16 w-full text-center text-lg">
										<span className="text-gray-700">There are no pages.</span>
										<strong onClick={openModal} className="cursor-pointer hover:underline">Create one</strong>!
									</div>
								}
								
								<div className="mt-6">
									<DndContext
										sensors={sensors}
										collisionDetection={closestCenter}
										onDragEnd={handleReorder}
									>
										<SortableContext
											items={pagesList?.data}
											strategy={verticalListSortingStrategy}
										>
											{pagesList?.data.map((page) => (
												<PageItem
													key={page.id}
													page={page}
													delete={(e) => handleDelete(e, page.id)}
												/>
											))}
										</SortableContext>
									</DndContext>
								</div>
							</div>
							
							<Modal
								title="Create a New Page"
								show={showModal}
								close={handleCloseModal}
								processSubmit={handleProcessModal}
								isLoading={isPageCreateLoading}
							>
								<p className="mb-4 text-sm text-gray-400">You can edit your page once you create it!</p>
								<p className="text-sm text-gray-700">
									{process.env.NEXT_PUBLIC_ORIGINAL_URL}/
									<input
										type="text"
										className="border p-2"
										placeholder="mygreatpage"
										required
										onChange={e => {
											setSlug(e.target.value)
											setErrorList({})
										}}
									/>
								</p>
								{showError('slug', errorList)}
							</Modal>
						</>
					}
				
				</div>
			</Layout>
		);
	}
}

export default Dashboard;
