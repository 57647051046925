import React, {useState, useEffect} from 'react';
import classNames from "classnames";
import {HiOutlineExclamation, HiX} from 'react-icons/hi'

function Modal({
	title,
	size,
	type,
	actions,
	close,
	show,
	processSubmit,
	children,
   	hideSubmitButton = false,
	disableSubmitButton = false,
	isLoading,
	submitText
}) {
	
	const [showExclamationIcon, setShowExclamationIcon] = useState(false);
	
	const modalClasses = () => {
		return classNames(
			'inline-block',
			'align-bottom',
			'bg-white',
			'rounded-lg',
			'text-left',
			'overflow-hidden',
			'shadow-xl',
			'transform',
			'transition-all',
			'sm:my-8',
			'sm:align-middle',
			'w-full',
			'lg:w-3/4',
			size === undefined ? 'sm:max-w-lg' : 'sm:max-w-'+size,
			!actionsEnabled() ? 'pb-1' : null
		)
	}
	
	useEffect(() => {
		if (type === 'warning' || type === 'danger') {
			setShowExclamationIcon(true)
		}
	},[type]);
	
	function getType() {
		if (!type) {
			return 'primary'
		}
		
		return type
	}
	
	function actionsEnabled() {
		if (actions === undefined) {
			return true
		}
		
		return actions;
	}
	
	
	function closeModal() {
		close()
	}
	
	function getSubmitButtonClass() {
		return 'modal-btn-' + getType()
	}
	
	function getModalIconClass() {
		return 'modal-icon-' + getType()
	}
	
	return (
		<>
			{show &&
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" onClick={() => closeModal()}></div>
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
						<form className={modalClasses()} onSubmit={(e) => processSubmit(e)}>
							<div className="bg-white px-4 pt-5 pb-3 sm:p-6 sm:pb-4">
								<div className="flex items-start mb-6 items-center">
									{showExclamationIcon &&
										<div
											className={classNames('mx-auto', 'flex-shrink-0', 'flex', 'items-center', 'justify-center', 'h-12', 'w-12', 'rounded-full', 'mr-2', 'sm:mr-0', 'sm:mx-0', 'sm:h-10', 'sm:w-10', getModalIconClass())}
										>
											<HiOutlineExclamation className={classNames('h-6', 'w-6', 'modal-icon'+getType())} />
										</div>
									}
									
									<div className="text-center w-full sm:mt-0 sm:ml-4 sm:text-left">
										<div className="flex justify-between">
											<h3 className="text-2xl leading-6 font-medium text-gray-900" id="modal-title">
												{title}
											</h3>
											<HiX onClick={() => closeModal()} className="-mt-1 w-6 h-6 cursor-pointer" fill="currentColor" />
										</div>
									</div>
								</div>
								<div className="mt-3 sm:mt-0 sm:mx-4 sm:text-left">
									<div className="mt-2">
										{children}
									</div>
								</div>
							</div>
							{actionsEnabled() &&
								<div className="bg-gray-50 px-4 py-3 px-6 flex flex-row-reverse">
									{hideSubmitButton ?
										''
										:
										<button
											type="submit"
											disabled={(isLoading || disableSubmitButton) ?? false}
											className={getSubmitButtonClass()}
										>
											{submitText ?? 'Submit'}
										</button>
									}
									<button type="button" className="modal-btn-cancel" onClick={() => closeModal()}>Cancel</button>
								</div>
							}
						</form>
					</div>
				</div>
			}
		</>
	);
}

export default Modal;
