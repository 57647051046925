import React from 'react';
import classNames from "classnames";

function ContextMenuItem(props) {
	
	function handleOnClick() {
		
		if (props.href) {
			if (props.newTab) {
				window.open(props.href);
			} else {
				window.location.href = props.href;
			}
		}
		
		if (props.onClick) {
			props.onClick()
		}
	}
	
	return (
		<span className="context-menu-item" onClick={handleOnClick}>
			{props.icon &&
				props.icon
			}
			<span className={
				classNames({
					'text-red-500':props.destructive === true
				})
			}>
				{props.text
					? props.text
					: props.children
				}
			</span>
		</span>
	);
}

export default ContextMenuItem;
