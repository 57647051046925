import { apiSlice } from "./apiSlice"
import {deepmerge} from "../helpers/utils";

const apiWithTag = apiSlice.enhanceEndpoints({addTagTypes: ['Page', 'Dashboard']})

const extendedApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getPages: build.query({
			query: () => ({url: '/page', method: 'get' }),
			providesTags: ['Page'],
		}),
		getPage: build.query({
			query: id => ({url: '/page/' + id, method: 'get'}),
			providesTags: (result, error, arg) => [{ type: 'Page', id: result?.id }]
		}),
		postCreatePage: build.mutation({
			query: postData => ({
				url: '/page',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: ['Page']
		}),
		updatePage: build.mutation({
			query: ({ id, ...patch }) => ({
				url: '/page/' + id,
				method: 'PUT',
				body: patch.data
			}),
			
			async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					apiSlice.util.updateQueryData('getPage', id, draft => {
						Object.assign(draft, deepmerge(draft, patch))
					})
				)
				try {
					await queryFulfilled
				} catch {
					patchResult.undo()
				}
			}
		}),
		deletePage: build.mutation({
			query: postData => ({
				url: '/page/' + postData.data.page_id,
				method: 'DELETE',
				body: postData.data
			}),
			invalidatesTags: ['Dashboard']
		}),
		postResetPage: build.mutation({
			query: postData => ({
				url: '/page/' + postData.data.page_id + '/reset',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postAdminSearch: build.mutation({
			query: postData => ({
				url: '/page/admin/search',
				method: 'POST',
				body: postData.data
			})
		}),
		postAdminAdd: build.mutation({
			query: postData => ({
				url: '/page/' + postData.data.page_id + '/admin/add',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postAdminTransfer: build.mutation({
			query: postData => ({
				url: '/page/' + postData.data.page_id + '/admin/transfer',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postAdminRemove: build.mutation({
			query: postData => ({
				url: '/page/' + postData.data.page_id + '/admin/remove',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postAdminRemoveMyself: build.mutation({
			query: postData => ({
				url: '/page/' + postData.data.page_id + '/admin/remove/myself',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postAdminResendInvite: build.mutation({
			query: postData => ({
				url: '/page/' + postData.data.page_id + '/admin/' + postData.data.user_id + '/resend-invite',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postChangeSlug: build.mutation({
			query: postData => ({
				url: '/page/' + postData.data.page_id + '/slug/change',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		
		postSnapshotTake: build.mutation({
			query: postData => ({
				url: '/snapshot/take/' + postData.data.page_id,
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postSnapshotRename: build.mutation({
			query: postData => ({
				url: '/snapshot/' + postData.data.snapshot_id + '/rename',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		deleteSnapshot: build.mutation({
			query: postData => ({
				url: '/snapshot/' + postData.data.snapshot_id,
				method: 'DELETE',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postRestoreSnapshot: build.mutation({
			query: postData => ({
				url: '/snapshot/' + postData.data.snapshot_id + '/restore',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Page', id: arg.id }]
		}),
		postReorderPage: build.mutation({
			query: postData => ({
				url: '/page/order',
				method: 'POST',
				body: postData.data
			}),
			invalidatesTags: ['Page']
		}),
		getPageAnalytics: build.query({
			query: data => {
				let url = '/' + data.type + '/' + data.id + '/analytics'
				
				if (data.startDate && data.startDate !== null) {
					url = url + '?start_date=' + data.startDate
				}
				
				if (data.endDate && data.endDate !== null) {
					url = url + '&end_date=' + data.endDate
				}
				
				// if (data.startDate && data.endDate === null) {
				// 	url = url + '&end_date=' + Date.parse(startDate) / 1000
				// }
				
				return ({
					url,
					method: 'get'
				})
			}
		}),
	}),
	overrideExisting: false,
})

export const {
	// general
	useGetPagesQuery,
	useGetPageQuery,
	usePostCreatePageMutation,
	useUpdatePageMutation,
	useDeletePageMutation,
	usePostResetPageMutation,
	usePostReorderPageMutation,
	useGetPageAnalyticsQuery,
	
	// admin stuff
	usePostAdminSearchMutation,
	usePostAdminAddMutation,
	usePostAdminTransferMutation,
	usePostAdminRemoveMutation,
	usePostAdminRemoveMyselfMutation,
	usePostAdminResendInviteMutation,
	
	// slug
	usePostChangeSlugMutation,
	
	// snapshot
	usePostSnapshotTakeMutation,
	usePostSnapshotRenameMutation,
	useDeleteSnapshotMutation,
	usePostRestoreSnapshotMutation,
	
	util: { getRunningQueriesThunk, resetApiState, getState }
	
} = extendedApi

export const {
	getPage,
	getPages,
	getAllContent
} = extendedApi.endpoints;
