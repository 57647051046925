import React from 'react';
import classNames from "classnames";

function ContextMenu(props) {
	
	function toggleOptionsMenu() {
		props.close()
	}
	
	return (
		<>
			<div className="fixed inset-0 bg-gray-900 bg-opacity-0 transition-opacity z-50" onClick={() => toggleOptionsMenu()}>
			</div>
			<div
				className={classNames(
					'context-menu',
					'z-50',
					{'context-bottom':props.bottom === 'true'}
				)}
			>
				<div className="py-1">
					{props.children}
				</div>
			</div>
		</>
	);
}

export default ContextMenu;
